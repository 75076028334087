.download-app-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 50px 0; */
    align-items: center;
    color: var(--text-color-title);
    box-sizing: border-box;
    background-color: var(--background-color);
    padding: 50px 0px;
}

.inner-container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    margin: auto auto;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: 28px;
    padding: 0px 20px;
}

.inner-container-content {
    width: 100%;
    display: flex;
    margin: auto auto;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(125deg, #3D83FB 55%, #e15540);
    border-radius: 28px;
    padding: 40px 24px;
    padding-left: 52px;
}

.promotion-left-content {
    text-align: left;
    width: auto;
}

.promotion-left-content h2 {
    margin-top: 0;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 20px;
}

.store-badges {
    display: flex;
    gap: 10px;
}

.store-badge {
    width: 150px;
    /* Adjust the size as needed */
    height: auto;
    border-radius: 8px;
}

.promotion-right-content {
    height: auto;
    max-width: 100%;
    width: 200%;
    display: flex;
    justify-content: end;
}

.app-preview {
    width: 100%;
    max-width: 400px;
    /* Adjust the size as needed */
    height: auto;
}

/* Add additional styles as needed */
/* Tablets (portrait) */
@media only screen and (min-width: 600px) and (max-width: 959px) {
    .promotion-left-content {
      text-align: center;
    }
  
    .promotion-right-content {
      display: none;
    }
  
    .store-badges {
      justify-content: center;
    }

    .inner-container-content {
      padding-left: 0;
      padding: 40px 24px;
    }
  }
  
  /* Mobile devices (portrait) */
  @media only screen and (max-width: 599px) {
    .promotion-left-content {
      text-align: center;
    }
  
    .promotion-right-content {
      display: none;
    }
  
    .store-badges {
        display: grid;
      justify-content: center;
    }

    .inner-container-content {
      padding-left: 0;
      padding: 40px 24px;
    }
  }