.faq-container {
    width: 100%;
    background-color: var(--background-color-50);
    /* Add a background color for better visibility */
    color: var(--text-color-title);
    display: flex;
    text-align: center;
}

.faq-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 50px var(--page-margin);
    box-sizing: border-box;
    /* Adjust padding as needed */
}

.faq-section-description {
    margin-bottom: 30px;
}

.faq-item {
    width: 100%;
    border: 1px solid var(--divider-color);
    margin-bottom: 10px;
    border-radius: 16px;
    overflow: hidden;
    display: inline-block;
    text-align: left;
}

.faq-item .open {
    max-height: 500px; /* Set a larger max-height than expected content */
  }

.faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    cursor: pointer;
    /* background-color: #f4f4f4; */
}

.faq-header h4 {
    font-weight: 500;
}

.faq-answer {
    padding: 20px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    color: var(--text-color-caption);
    /* Animation for max-height */
}

/* Icon styles */
.fas {
    font-size: 18px;
    transition: transform 0.1s ease;
}

.fas.fa-chevron-up {
    transform: rotate(360deg);
}