/* Footer.css */
@import url(../../material3/themes/Material.css);

.footer {
    background-color: var(--background-color);
    justify-content: center;
    padding: 64px 0;
    padding-bottom: 24px;
  }

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    justify-content: space-between;
    box-sizing: border-box;
    display: flex;
    gap: 24px;
  }
  
  .footer-section {
    max-width: 300px;
  }
  
  .company-info,
  .grandquiz-info {
    text-align: left;
  }
  
  .company-info img,
  .grandquiz-info img {
    max-width: 48px;
    margin-bottom: 10px;
  }
  
  .company-info p,
  .grandquiz-info p {
    color: var(--text-color-caption);
    margin-bottom: 10px;
    font-size: 0.8em;
  }
  
  .company-info p a,
  .grandquiz-info p a {
    color: var(--primary-color);
    text-decoration: underline;
    margin-bottom: 0px;
    display: inline;
  }
  
  .company-info a:hover,
  .grandquiz-info a:hover {
    text-decoration: underline;
  }
  
  .footer h4 {
    color: var(--text-color-title);
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: 500;
  }
  
  .footer a {
    color: var(--text-color-caption);
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    font-size: 0.9em;
  }

  .footer .contact p {
    color: var(--text-color-caption);
    font-size: 0.9em;
  }

  .footer .contact a {
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  .footer-section a:hover {
    color: var(--secondary-color);
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
  }
  
  .footer ul li {
    margin-bottom: 5px;
  }

  .footer-divider {
    background-color: var(--divider-color);
    height: 1px;
    color: var(--divider-color);
    margin: 16px 0;
  }

  .social-icons {
    font-size: 1.2em;
    display: flex;
    gap: 20px;
  }

  .social-icons a {
    color: var(--text-color-title);
    font-size: 20px;
    text-decoration: none;
    margin-bottom: 0;
  }

  .facebook:hover {
    color: #316FF6;
  }

  .instagram:hover {
    color: #E4405F;
  }

  .twitter:hover {
    color: #1DA1F2;
  }

  .youtube:hover {
    color: #CD201F;
  }
  
  .copyright-text {
    font-size: 14px;
  }

  .footer-section-2 {
    max-width: 1200px;
    margin: 0 auto;
    color: var(--text-color-title);
    box-sizing: border-box;
    padding: var(--page-margin);
  }  

  .footer-social {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Add additional styles as needed */
/* Tablets (portrait) */
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .promotion-right-content {
    display: none;
  }

  .footer-social {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}

/* Mobile devices (portrait) */
@media only screen and (max-width: 599px) {
  .footer-container {
    display: grid;
    text-align: center;
  }

  .footer-section {
    text-align: center;
    max-width: 100%;
  }

  .grandquiz-info {
    text-align: center;
  }

  .footer-social {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}