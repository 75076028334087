/* Header.css */
@import url(../../material3/themes/Material.css);

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    background-color: var(--background-color);
    /* Header background color */
    color: #ffffff;
    align-items: center;
    display: flex;
    z-index: 100000;
    border-bottom: 1px solid var(--divider-color);
    /* Header text color */

}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px var(--page-margin);
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    /* Set the maximum width */
    margin: 0 auto;
    /* Center the container horizontally */
}

.header-content img {
    display: flex;
}

.logo {
    width: 48px;
    height: 48px;
}

nav {
    display: flex;
    align-items: center;
    gap: 48px;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 48px;
}

nav a {
    text-decoration: none;
    color: var(--text-color-title);
    font-weight: 400;
    font-size: 1rem;
}

nav a:hover {
    color: var(--secondary-color);
}

nav a:active {
    color: var(--secondary-color);
}

/* Add additional styles as needed */
/* Tablets (portrait) */
@media only screen and (min-width: 600px) and (max-width: 959px) {}

/* Mobile devices (portrait) */
@media only screen and (max-width: 599px) {
    nav .menu-items {
        display: none;
    }
}