:root {
  --primary-color: #3D83FB;
  --primary-color-50: #b3e0ff;
  --primary-color-100: #80c7ff;
  --primary-color-200: #4da6ff;
  --primary-color-300: #2680ff;
  --primary-color-400: #3D83FB;
  --primary-color-500: #0056b3;
  --primary-color-600: #004080;
  --primary-color-700: #002b66;
  --primary-color-800: #001a40;
  --primary-color-900: #000f33;

  --secondary-color: #e15540;
  --secondary-color-50: #f26c5a;
  --secondary-color-100: #f87b6c;
  --secondary-color-200: #fa8a7e;
  --secondary-color-300: #fd9a90;
  --secondary-color-400: #ffaaa2;
  --secondary-color-500: #e15540;
  /* Keeping the original color as 500 */
  --secondary-color-600: #c7462f;
  --secondary-color-700: #a8361e;
  --secondary-color-800: #89260d;
  --secondary-color-900: #6b1500;

  --background-color: #0D1013;
  --background-color-50: #121518;
  --background-color-100: #424242;
  --background-color-200: #616161;
  --background-color-300: #757575;
  --background-color-400: #bdbdbd;
  --background-color-500: #9e9e9e;
  --background-color-600: #e0e0e0;
  --background-color-700: #eeeeee;
  --background-color-800: #f5f5f5;
  --background-color-900: #fafafa;

  --text-color-body: rgba(255, 255, 255, 1);
  --text-color-title: rgba(255, 255, 255, 1);
  --text-color-caption: rgba(255, 255, 255, 0.7);
  --text-color-headline: rgba(255, 255, 255, 1);
  --text-color-disabled: rgba(255, 255, 255, 0.5);

  --page-margin: 20px;
  --divider-color: #424242;
  --header-height: 88px;
}

/* Global styles */
body {
  margin: 0;
  font-family: 'Poppins', Arial, sans-serif;
}

.app {
  transition: background-color 0.3s, color 0.3s;
}

.button {
  background-color: var(--primary-color);
  border: none;
  padding: 12px 12px;
  min-width: 96px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 12px;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.button:hover {
  background-color: var(--primary-color-400);
}

.button:active {
  background-color: var(--primary-color-600);
}