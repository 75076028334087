/* FeaturesSection.css */
@import url(../../material3/themes/Material.css);

.features-section {
    width: 100%;
    background-color: var(--background-color-50);
    /* Background color for the section */
    padding: 50px 0;
    /* Adjust the padding as needed */
}

.features-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 20px;
    box-sizing: border-box;
}

.features-section h1 {
    margin: 0px;
    color: var(--text-color-title);
    /* Title color */
}

.features-section p {
    color: var(--text-color-caption);
    /* Description color */
    margin-bottom: 30px;
    /* Adjust the margin as needed */
}

.features-list {
    list-style-type: none;
    padding: 0;
}

.features-list li {
    font-size: 18px;
    margin-bottom: 10px;
    color: #444;
    /* Feature item color */
    text-align: center;
}


.features-content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
}

.features-content img {
    height: 28px;
    color: var(--secondary-color);
    margin-bottom: 2px;
    translate: 0px -4px;
}

.feature-item {
    text-align: left;
    border: 1px solid var(--divider-color);
    background-color: var(--background-color);
    /* Border color */
    border-radius: 16px;
    padding: 32px;
    /* Adjust the padding as needed */
    transition: transform 0.3s ease; /* Transition effect on hover */
}

.feature-item i {
    font-size: 24px;
    color: var(--secondary-color);
    /* Icon color */
    margin-bottom: 12px;
}

.feature-item h3 {
    font-size: 20px;
    color: var(--text-color-title);
    /* Title color */
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px;
}

.feature-item p {
    font-size: 16px;
    margin: 0;
    color: var(--text-color-caption);
    /* Description color */
}

.feature-item:hover {
    transform: scale(1.05); /* Scale up on hover */
  }