/* BannerComponent.css */
@import url(../../material3/themes/Material.css);
@import url('https://fonts.googleapis.com/css2?family=Dimbo&display=swap');

.banner-container {
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: var(--text-color-title);
  padding-top: var(--header-height);
}

.banner-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  padding: 56px var(--page-margin);
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.left-content {
  width: auto;
}

.left-content h2 {
  font-size: 2.5em;
}

.brand-name {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-family: 'Dimbo';
  letter-spacing: 2px;
}

.right-content {
  height: auto;
  max-width: 100%;
  width: 200%;
  padding-left: 200px;
  box-sizing: border-box;
}

.badges {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.badges img {
  margin-right: 10px;
  max-height: 40px;
  border: 1px solid var(--text-color-title);
  border-radius: 8px;
  /* Adjust the height based on your design */
}

/* Additional SEO-friendly styles */
h1,
p {
  margin: 0;
  /* Remove default margins for better SEO */
}

.banner-content h1 {
  font-size: 3em;
  margin-bottom: 24px;
}

.banner-content p {
  margin-bottom: 48px;
}

.banner-content img {
  max-width: 100%;
  /* Ensure images don't overflow their containers */
}

.emphasize-title {
  font-weight: bold;
  /* You can adjust styles based on your design preferences */
  color: var(--secondary-color);
  /* Highlight color */
}

.emphasize-body {
  font-weight: 500;
  /* You can adjust styles based on your design preferences */
  color: var(--secondary-color);
  /* Highlight color */
}


/* Add additional styles as needed */
/* Tablets (portrait) */
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .banner-content {
    flex-direction: column-reverse;
    text-align: center;
  }

  .right-content {
    width: 60%;
    padding: 24px 56px;
  }

  .badges {
    justify-content: center;
  }
}

/* Mobile devices (portrait) */
@media only screen and (max-width: 599px) {
  .banner-content {
    flex-direction: column-reverse;
    text-align: center;
  }

  .right-content {
    padding: 24px 56px;
  }

  .badges {
    justify-content: center;
  }
}