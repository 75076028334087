@import url(../../material3/themes/Material.css);

.terms-container {
    padding-top: var(--header-height);
    box-sizing: border-box;
    color: var(--text-color-title);
    background-color: var(--background-color-50);
}

.terms-content {
    max-width: 1200px;
    display: block;
    padding: 50px 20px;
    box-sizing: border-box;
    margin: 0 auto;
    justify-content: center;
}

.terms-container a {
    color: var(--primary-color);
}

.terms-container a:hover {
    color: var(--secondary-color);
}

.terms-content h2 {
    margin-top: 0;
}