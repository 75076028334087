/* Reviews.css */
@import url(../../material3/themes/Material.css);
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.reviews-container {
    background-color: var(--background-color);
    color: var(--text-color-title);
}

.reviews-section {
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    padding: 50px 20px;
    box-sizing: border-box;
    justify-content: center;
}

.reviews-header {
    text-align: center;
    margin-bottom: 20px;
}

.reviews-header h1 {
    margin: 0px;
}

.reviews-header p {
    margin-bottom: 30px;
    color: var(--text-color-caption);
}

.reviews-list {
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    cursor: grab;
    user-select: none;
    scrollbar-color: red yellow;
}

.reviews-list:active {
    cursor: grabbing;
}

.review-card {
    width: 300px;
    min-width: 240px;
    padding: 24px;
    background-color: var(--background-color-50);
    /* border: 1px solid var(--divider-color); */
    border-radius: 16px;
}

.scroll-button {
    width: 40px;
    height: 40px;
    margin: 16px 4px;
    background-color: var(--secondary-color);
    color: var(--text-color-title);
    border: none;
    border-radius: 1000px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.scroll-button:disabled {
    background-color: var(--background-color-100);
    cursor: not-allowed;
    color: var(--text-color-disabled);
}

.scroll-buttons {
    display: flex;
    justify-content: center;
}

.review-text {
    color: var(--text-color-title);
    margin-bottom: 24px;
    font-size: 0.9em;
}

.review-username {
    color: var(--text-color-caption);
    font-size: 0.9em;
}